import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Slider from "react-slick";
import ComponentMain2 from "../../components/main/component-main-2";
import ComponentMain5 from "../../components/main/component-main-5";
import ComponentTextblock4 from "../../components/component-textblock-4";
import ComponentColumn2 from "../../components/component-column-2";//import ComponentPics2 from "../../components/pics/component-pics-2";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const TreatmentCvd = ({ data, location }) => {
  const intl = useIntl();

  //const [isToggleOpen, setToggleOpen] = useState([]);
  //const handleToggle = (e) => {
  //  setToggleOpen({
  //    ...isToggleOpen,
  //    [e.currentTarget.id]: isToggleOpen[e.currentTarget.id] ? false : true,
  //  });
  //};

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Layout location={location} locale={intl.locale} mode="beige">
      <Seo
        title={
          intl.locale === "en"
            ? `Revolutionary Robotic Treatment for Patients  with stroke`
            : `Bahnbrechende robotische Therapie bei Schlaganfallpatienten`
        }
        description={
          intl.locale === "en"
            ? `Neuromuscular feedback therapy helps people suffered from cerebrovascular disease improve functional mobility.`
            : `Neuromuskuläre Feedbacktherapie hilft Patienten mit zerebrovaskulären Erkrankungen, ihre funktionelle Mobilität zu verbessern.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/5L0ClmBFbi9APIj90aPWuv/c0d374a5912e5d1edfec8d658b29f7cf/E177.jpg?w=1200&h=630&fit=fill"
      />

      <ComponentMain2
        title={
          intl.locale === "en" ? `One Step further` : `Einen Schritt voraus`
        }
        label={
          intl.locale === "en"
            ? `Cerebrovascular disease (CVD)`
            : `Zerebrovaskulären Erkrankungen`
        }
        content={
          intl.locale === "en"
            ? `From the perspective of motor learning, repetitive movement and voluntary exercise are important elements. HAL satisfies the elements by generating sufficient motion assist in response to the wearer’s voluntary drive.`
            : `Aus der Perspektive des motorischen Lernens, sind wiederholte Bewegungen und willkürlich durchgeführte Übungen wichtige Elemente. HAL nutzt diese Elemente in dem genügend Bewegungsunterstützung, als Antwort auf die Bewegungsintention des Trägers, zur Verfügung gestellt wird.`
        }
        image="/images/treatment/cvd/31.jpg"
      />

      <ComponentTextblock4
        content={
          intl.locale === "en"
            ? `Why do we treat neurological difficulties with physical therapy but not tackle the source of the problem?`
            : `Weshalb behandeln wir neurologische Probleme mit Physiotherapie, aber gehen die Ursache des Problems nicht an?`
        }
      />

      <section className="bg-white">
        <div className="container">
          <div className="flex-row mx-n2 mb-6 pict-3">
            <div className="px-2">
              <StaticImage
                src="../../images/treatment/cvd/32.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/treatment/cvd/34.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/treatment/cvd/35.jpg"
                className="mb-4"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_third bg-white">
        <div className="container">
          <ComponentColumn2
            lefttitle={
              intl.locale === "en"
                ? `More Sense in Neurorehabilitation`
                : `Mehr Sinneswahrnehmung in der neurologischen Rehabilitation`
            }
            leftcontent={
              intl.locale === "en"
                ? `Why do we treat neurological difficulties with physical therapy but not tackle the source of the problem? It makes more sense to utilize neural information captured by HAL to treat neurological conditions. This neurorehab device helps the wearer move limbs based on the signals sent from the brain to the muscle detected through the skin surface, in order to improve ambulatory function in patients with spinal cord injury, stroke and several other neuromuscular diseases.`
                : `Weshalb behandeln wir neurologische Probleme mit Physiotherapie, aber gehen die Ursache des Problems nicht an? Es macht mehr Sinn, die von HAL erfassten neurologischen Informationen zu nutzen und so neurologische Probleme zu therapieren. HAL als neurorehabilitatives Produkt hilft dem Träger die Extremitäten auf Grundlage der vom Gehirn an die Muskeln gesendeten Signalen zu bewegen. Diese Signale werden von der Hautoberfläche, mittels Elektroden erfasst. Die Gehfähigkeit von Patienten mit Rückenmarksverletzungen, Schlaganfällen und anderen neuromuskulären Erkrankungen kann dadurch verbessert werden.`
            }
            label={intl.locale === "en" ? `HAL Treatment` : `HAL-Training`}
            list={
              intl.locale === "en"
                ? [
                    "Sensing neural information to treat neurological conditions",
                    "Active participation in patient-driven robotic rehabilitation",
                    "Empowering patients to walk without HAL after receiving HAL therapy",
                    "HAL therapy is provided by medical professionals",
                    "Ability to provide a variety of exercises other than walking, and to work on specific joints.",
                  ]
                : [
                    "Erfassen neurologischer Informationen um neurologische Probleme zu therapieren",
                    "Aktive Teilnahme an vom Patienten gesteuerter robotischer Rehabilitation",
                    "Patienten befähigen nach der HAL-Therapie ohne HAL zu gehen",
                    "Die HAL-Therapie wird von medizinischen Fachkräften begleitet",
                    "Ausführen vielfältiger Übungen außer dem Laufen und Möglichkeit, sich auf ein Gelenk zu fokussieren",
                  ]
            }
          />
        </div>
      </section>

      <ComponentMain5
        image="/images/treatment/cvd/36.jpg"
      />

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en" ? `HAL in action` : `HAL in Aktion`}
          </p>
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `Watch the video to see how Cybernics Technology is applied in
  therapy.`
              : `Schauen Sie sich das Video an und sehen Sie, wie die Cybernics Technologie in der Therapie angewendet wird.`}
          </p>
          <div className="slide">
            <Slider {...settings}>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Oaw7H03Yzw8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `HAL-therapy using the All-in-One Walker with a stroke patient`
                      : `HAL-Training im All-in-One Walker mit einem Schlaganfallpatienten`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/bcpJHLLISyU"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Performing squats with HAL Lower Limb`
                      : `Kniebeugen mit dem HAL Lower Limb`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/RuoyJt3Y6dc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Train you weaker leg in a standing position`
                      : `Gezieltes Training des schwachen Beines im Stehen`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/7iye7cxtizA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `HAL Single joint offers feedback via different colours`
                      : `HAL Single Joint gibt dem Träger Rückmeldung mittles verschiedener Farben`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/09ftmJzk0Q4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Train you paretic arm with HAL Single Joint`
                      : `Training des paretischen Arms mit dem HAL Single Joint`}
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-rot">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about HAL Treatment`
              : `Mehr über das HAL Training erfahren`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Spinal Cord Injury`
                    : `Rückenmarksverletzungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Locomotor training performed using a neurologically controlled
  cyborg HAL enables the neural activity and repetitive
  excercises of specific tasks.`
                    : `Lokomotionstraining mit dem neurologisch kontrollierten Cyborg HAL ermöglicht neuronale Aktivität und wiederholte Durchführung spezifischer Aufgaben.`}
                </p>
                <Link to="/treatment/spinal/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Progressive neuromuscular diseases`
                    : `Progressive neuromuskuläre Erkrankungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The 5 years Post-marketing surveillance results suggest that
  treatment with the HAL system shows improvement in gait
  related outcome measures despite the progressive nature of the
  diseases.`
                    : `Beobachtungen 5 Jahre nach Einführung des HALs zeigen, dass die HAL-Therapie zu Verbesserungen in Testungen in Bezug auf die Gehfähigkeit führt, trotz der fortschreitenden Art der Erkrankung.`}
                </p>
                <Link to="/treatment/pnd/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `About ZNB - Our Therapist`
                    : `Das ZNB / Unsere Therapeuten`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Meet our team. We offer exceptional level of personalised
  skill and expertise.`
                    : `Treffen Sie unser Team. Wie bieten ein außergewöhnliches Level an individuellen Fähigkeiten und Fachwissen. `}
                </p>
                <Link to="/treatment/znb/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Testimonials` : `Erfahrungsberichte`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Discover real stories from real people about how Cyberdyne
  technology changed their lives forever.`
                    : `Entdecken Sie reale Geschichten von Patienten darüber wie Cyberdyne deren Leben für immer verändert hat.`}
                </p>
                <div>
                  <Link to="/treatment/testimonials/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Frequently Asked Questions`
                    : `Häufig gestellte Fragen (FAQ)`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Here are our general frequently asked questions. For more
  specific questions, please contact us directly.`
                    : `Hier finden Sie häufig gestellte Fragen. Für spezifische Fragen kontaktieren Sie uns bitte direkt.`}
                </p>
                <div>
                  <Link to="/treatment/faq/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `HAL— Improving Mobility Wellness One Step at a Time`
              : `HAL - Verbessert das Bewegungswohlbefinden Schritt für Schritt`}
          </p>
          <p>
            {intl.locale === "en"
              ? `Gain a deeper understanding of the science and medicine of motor
  function and neural connections through the HAL Lower Limb.`
              : `Bekommen Sie ein besseres Verständnis für die wissenschaftlichen und medizinischen Aspekte der motorischen Funktion und neuronalen Verbindungen des HALs.`}
          </p>
          <div>
            <Link to="/contact/" className="link-button">
              {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TreatmentCvd;
